<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-22 16:58:30
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-01-22 17:15:34
 * @FilePath: /ww-swisstyle/src/views/tab.vue
 * @Description: 产品分类展示页面
-->
<template>
  <div class="tab-container">
    <!-- 分类标题 -->
    <div class="section-header text-center">
      <h2 class="main-title">Products</h2>
      <h3 class="sub-title">产品展示</h3>
    </div>

    <!-- 分类切换 -->
    <div class="tab-nav">
      <div class="container">
        <ul class="nav justify-content-center">
          <li class="nav-item" v-for="(category, index) in categories" :key="index">
            <a class="nav-link" 
               :class="{ active: currentCategory === category.key }"
               href="#"
               @click.prevent="switchCategory(category.key)">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- 产品展示区域 -->
    <section class="product-showcase">
      <div class="container">
        <div class="tag-container">
          <div class="tag-item" 
               v-for="(product, index) in currentProducts" 
               :key="index"
               @click="lookDetail(product)">
            {{ product.title }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  data() {
    return {
      currentCategory: 'strategy',
      categories: [
        { key: 'strategy', name: '策略服务' },
        { key: 'design', name: '设计服务' },
        { key: 'package', name: '包装服务' }
      ],
      products: {
        strategy: [
          { title: '包装策略规划' },
          { title: '产品命名与梳理' },
          { title: '年度包装与设计服务' },
          { title: '全产品线包装设计及延展' },
          { title: '主视觉及物料设计' },
          { title: '包装造型与瓶型设计' }
        ],
        design: [
          { title: '包装插画设计' },
          { title: '包装陈列设计' },
          { title: '饼干麦片包装' },
          { title: '调味品包装' },
          { title: '巧克力糖果包装' }
        ],
        package: [
          { title: '咖啡包装包装' },
          { title: '乳制品包装' },
          { title: '饮品包装包装' },
          { title: '婴儿食品包装' }
        ]
      }
    }
  },
  computed: {
    currentProducts() {
      return this.products[this.currentCategory] || []
    }
  },
  methods: {
    switchCategory(category) {
      this.currentCategory = category
    },
    lookDetail(product) {
      this.$router.push({
        path: '/detail',
        query: { 
          category: this.currentCategory,
          title: product.title
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tab-container {
  padding: 60px 0;
  background: #fff;

  .section-header {
    margin-bottom: 40px;
    
    .main-title {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      color: #333;
      font-weight: 300;
    }
    
    .sub-title {
      font-size: 1.5rem;
      color: #666;
      font-weight: 400;
    }
  }

  .tab-nav {
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;

    .nav {
      .nav-item {
        margin: 0 15px;

        .nav-link {
          color: #666;
          font-size: 16px;
          padding: 15px 0;
          border-bottom: 2px solid transparent;
          transition: all 0.3s ease;

          &:hover {
            color: #333;
          }

          &.active {
            color: #007bff;
            border-bottom-color: #007bff;
          }
        }
      }
    }
  }

  .product-showcase {
    .container {
      padding: 0 20px;
    }
    
    .tag-container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: center;
      padding: 20px 0;
    }
    
    .tag-item {
      padding: 8px 20px;
      background: #f8f9fa;
      border-radius: 20px;
      color: #333;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 1px solid #eee;
      
      &:hover {
        background: #007bff;
        color: white;
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      }
    }
  }
}

@media (max-width: 768px) {
  .tab-container {
    padding: 40px 0;

    .section-header {
      margin-bottom: 30px;
      
      .main-title {
        font-size: 2rem;
      }
      
      .sub-title {
        font-size: 1.2rem;
      }
    }

    .tab-nav {
      margin-bottom: 30px;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;

      .nav {
        flex-wrap: nowrap;
        
        .nav-item {
          margin: 0 10px;

          .nav-link {
            font-size: 14px;
            padding: 10px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .tab-container {
    padding: 30px 0;
    
    .product-showcase {
      .product-item {
        img {
          max-height: 300px;
        }
      }
    }
  }
}
</style>
